import React, {useEffect} from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useClickedOutsideOfComponent(ref, doThings) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                // console.log("You clicked outside of me!");
                if (doThings) {
                    doThings()
                }
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("touchstart", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        };
    }, [ref]);
}